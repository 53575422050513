import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";

const ROOT_API = process.env.REACT_APP_API_URL

function Job(props) {
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [jobNo, setJobNo] = useState("");

    const [isLoadingJob, setIsLoading] = useState(true);
    const [data, setData] = useState("");
    var { id } = useParams()


    useEffect(() => {
        const fetchJob = async () => {
            try {
                const data = fetch (`${ROOT_API}/jobs/${id}`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json; charset=UTF-8",
                        "Accept": "application/json; charset=UTF-8",
                        "Connection": "close",
                        "session-id": JSON.parse(localStorage.getItem('currentUser')).["session-id"]
                    }
                });
                const dataJSON = await data.json();          
                if (dataJSON["error-code"] === 404) {
                    setIsLoading(false);
                    setErrorMsg(dataJSON["error-message"]);
                    setIsError(true);
                } else {
                    setIsLoading(false);
                    setData(dataJSON.data);
                };
            } catch (error) {
                setIsLoading(false);
                setErrorMsg(error);
                setIsError(true);    
            }      
        }
        fetchJob()
    },[id, isLoadingJob])
    
    return (            
        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4 py-5">
            
            <div className="row col-12 center py-5">
                <div className="col-md-4 col-md-offset-4"> 
                
                {isError === false ? (<div>                
                    <h3>Policy And Customer Info</h3>
                    <p><strong>Policy Number:</strong> {data["policy-no"]}</p>
                    <p><strong>Repair No.</strong> {data["repair-reference"]}</p>
                    <p><strong>Policy Expiry:</strong> {data["policy-expiry"]}</p>                    
                    <h3>Product and Fault Info</h3>
                    <p><strong>Date Filed</strong> {data["file-date"]}</p>
                    <p><strong>Manufacturer</strong> {data["manufacturer"]}</p>
                    <p><strong>Product Type</strong> {data["product-type"]}</p>
                    <p><strong>Model Number</strong> {data["model-number"]}</p>
                    <p><strong>Fault</strong> {data["fault"]}</p>
                    <h3>Status</h3>
                    <p><strong>Post Code: </strong>{data["post-code"]}</p>
                    <p><strong>Job Status</strong> {data["job-status"]}</p>
                    <p><strong>Repair Date</strong> {data["repair-date"]}</p>
                    <p><strong>Dispatch Date</strong> {data["despatch-date"]}</p>
                    <p><strong>Additional Information: {data["spare-text3"]}</strong></p>
                    </div>) : (<p>{errorMsg}</p>)}
                </div>
            </div>
        </main>
    );
}

export default Job
